<template>
  <div class="home">
    <div v-if="isUserLogin" class="row justify-content-center">
      <div class="col-lg-4" style="text-align:center;">
        <b-card sub-title="國立臺東大學學生宿舍系統" title="你好">
          <b-card-text>
            你已經成功登入國立臺東大學學生宿舍系統。<br>
            手機請點選右上角 ≡選用功能
            <div v-if="isAdmin">
              此藍色區段僅有管理員可以看到：<br>
              <b-link :to="{name: 'partTimeCheckIn'}" class="card-link">學生報到</b-link>
              <br>
              提供一些快速連結，其他功能請使用上方導航列
            </div>
            <hr>

          </b-card-text>
          <div>
            <b-col>
              <b-button :to="{name: 'student_area_home'}" size="lg" variant="primary">入住報到</b-button>
            </b-col>
            <hr/>
            <b-col>
              <b-button :to="{name: 'borrow_apply'}" size="lg" variant="primary">申請借用物品</b-button>
            </b-col>
          </div>
          <!--
            <b-link :to="{name: 'student_area_home'}" class="card-link">資料總攬</b-link>
            <b-link :to="{name: 'student_area_email'}" class="card-link">Email維護</b-link>
            <b-link :to="{name: 'student_area_phone'}" class="card-link">手機維護</b-link>
            <b-link :to="{name: 'student_area_option'}" class="card-link">其他可選資訊</b-link>-->
        </b-card>
      </div>
    </div>
    <div v-else class="row justify-content-center">
      <div class="col-sm-4" style="text-align:center;">
        <b-card title="國立臺東大學學生宿舍系統">
          <b-link class="card-link" href="https://wdsa.nttu.edu.tw/p/412-1009-4629.php" style="font-size: 20px">
            學校宿舍公告網址
          </b-link>
          <br/>
          <b-link class="card-link" href="https://wdsa.nttu.edu.tw/p/405-1009-74635,c8462.php?Lang=zh-tw"
                  style="font-size: 20px">學校宿舍設備維修網址
          </b-link>
          <b-card-text>
            如果沒有特別去改，帳號密碼同校務系統。<br/><br/>

            8:00-17:30請至二宿櫃檯辦理<br/>
            18:00-22:00請至一宿櫃檯辦理<br/>
            夜間或緊急狀況請撥打<br/>
            宿舍值勤手機：<a href="tel:+886-963-020995">0963-020-995</a>
          </b-card-text>

          <b-link class="card-link" href="#"
                  onclick="window.location = 'https://sso.nttu.edu.tw/client/login?redirect_uri=https://dorm.nttu.club/api/auth/sso_callback&state=noNeed&client_id=5'"
                  style="font-size: 25px">登入
          </b-link>
          <!--<b-link href="/api/auth/force_login/10811217" class="card-link">工程模式</b-link>-->
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'home',
  components: {},
  computed: {
    isUserLogin() {
      return this.$store.state.userState.isLogin;
    },
    isAdmin() {
      return this.$store.state.userState.isAdmin;
    }
  }
};
</script>
